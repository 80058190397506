import { Accordion, AccordionItem, AccordionRefObject } from '@paljs/ui/Accordion';
import { Button } from '@paljs/ui/Button';
import { Card, CardBody } from '@paljs/ui/Card';
import Row from '@paljs/ui/Row';
import Col from '@paljs/ui/Col';
import Modal from 'react-modal';
import React, { useRef, useState } from 'react';
import SEO from '../components/SEO';
import AirBrakeCourseImg from '../images/airbrake-course-img.png';
const linkToPPTFile = 'https://scholar.harvard.edu/files/torman_personal/files/samplepptx.pptx';


  

const Accordions = () => {
  const accordionRef = useRef<AccordionRefObject>(null);
  const style = { marginBottom: '1.5rem' };

  const [isOpen, setIsOpen] = useState(false);
  const [isOpenThree, setIsOpenThree] = useState(false);

  function toggleModal() {
    setIsOpen(!isOpen);
  }

  function toggleModalThree() {
    setIsOpenThree(!isOpenThree);
  }

  return (
    <>
      <SEO title="Accordions" />
      <Row className="userTabs">
        <Col breakPoint={{ xs: 12, lg: 12 }}>
          <h2 style={{ textAlign: 'center' }}>Courses</h2>
          <Accordion multi ref={accordionRef}>
            <AccordionItem
              uniqueKey={1}
              title={
                <div className="userNameWrap">
                  <h6>Lesson 2</h6>
                  <p>Last Edited: June 3, 2022 12:22 AM</p>
                </div>
              }
            >
              <h6>Course Details: </h6>
              <p>
                <strong>Name: </strong>Lesson 2 <br />
                <strong>File: </strong>lesson-1-june.pdf <br />
                <strong>Time Duration: </strong> 38 minutes <br />
                <strong>Last Edited: </strong> June 3, 2022 12:22 AM
              </p>
              <h6> Cover Image: </h6>
              <img src={AirBrakeCourseImg} className="courseCoverImg"/>
              <div className="userButtonWrapper">
                <button id="editUserBtn" onClick={toggleModal}>Edit Lesson</button>
                <button id="deleteUserBtn">Delete Lesson</button>
                <button id="cancelReset" onClick={toggleModalThree}>Preview Course</button>
                {/* // these will all be unique PPTs in the modal thats being called on  */}
              </div>
            </AccordionItem>
            <AccordionItem
              uniqueKey={2}
              title={
                <div className="userNameWrap">
                  <h6>Lesson 2</h6>
                  <p>Last Edited: June 1, 2022 12:22 AM</p>
                </div>
              }
            >
             <h6>Course Details: </h6>
              <p>
                <strong>Name: </strong>Lesson 2 <br />
                <strong>File: </strong>lesson-2-june.pdf <br />
                <strong>Time Duration: </strong> 25 minutes <br />
                <strong>Last Edited: </strong> June 2, 2022 12:22 AM
              </p>
              <h6> Cover Image: </h6>
              <img src={AirBrakeCourseImg} className="courseCoverImg"/>
              <div className="userButtonWrapper">
                <button id="editUserBtn" onClick={toggleModal}>Edit Lesson</button>
                <button id="deleteUserBtn">Delete Lesson</button>
                <button id="cancelReset" onClick={toggleModalThree}>Preview Course</button>
                 {/* // these will all be unique PPTs in the modal thats being called on  */}
              </div>
            </AccordionItem>
            <AccordionItem
              uniqueKey={3}
              title={
                <div className="userNameWrap">
                  <h6>Lesson 3</h6>
                  <p>Last Edited: May 30, 2022 12:22AM</p>
                </div>
              }
            >
             <h6>Course Details: </h6>
              <p>
                <strong>Name: </strong>Lesson 3 <br />
                <strong>File: </strong>lesson-3-june.pdf <br />
                <strong>Time Duration: </strong> 18 minutes <br />
                <strong>Last Edited: </strong> June 3, 2022 12:22 AM
              </p>
              <h6> Cover Image: </h6>
              <img src={AirBrakeCourseImg} className="courseCoverImg"/>
              <div className="userButtonWrapper">
                <button id="editUserBtn" onClick={toggleModal}>Edit Lesson</button>
                <button id="deleteUserBtn">Delete Lesson</button>
                <button id="cancelReset" onClick={toggleModalThree}>Preview Course</button>
                 {/* // these will all be unique PPTs in the modal thats being called on  */}
              </div>
            </AccordionItem>
          </Accordion>
        </Col>

        {/* Edit Lesson Modal  */}
        <Modal isOpen={isOpen} onRequestClose={toggleModal} contentLabel="Modal">
          <h3>Edit Lesson 2</h3>
          <form className="modalEditWrap">
          <div>
            <label>Name:</label>
            <input type="email" id="exampleInputEmail1" value="Lesson 3" />
          </div>
          <div className='fileUploadWrap'>
              <label>File:</label>
              <div className='fileUploadWrapper'>
                <input type="file"></input>
                <span>lesson-1-june.pdf</span>
              </div>
          </div>
          <div>
            <label>Time Duration:</label>
            <input type="email" id="exampleInputEmail1" value="18 mins" />
          </div>
          <div className='fileUploadWrap'>
              <label>Cover Image:</label>
              <div className='fileUploadWrapper'>
                <input type="file"></input>
                <span>sosa-air-brake-course.png</span>
              </div>
          </div>
          <button id="updateUser">Update Lesson</button>
         
        </form>

          <a onClick={toggleModal} className="closeBtnModal">
          {' '}
          <svg xmlns="http://www.w3.org/2000/svg" width="17.688" height="17.688" viewBox="0 0 17.688 17.688">
            <path
              id="close-icon"
              d="M25.188,9.281,23.407,7.5l-7.063,7.063L9.281,7.5,7.5,9.281l7.063,7.063L7.5,23.407l1.781,1.781,7.063-7.063,7.063,7.063,1.781-1.781-7.063-7.063Z"
              transform="translate(-7.5 -7.5)"
              fill="#000"
            ></path>
          </svg>
        </a>
        </Modal>

        {/* // Preview Course Modal  */}
        <Modal isOpen={isOpenThree} onRequestClose={toggleModalThree} contentLabel="Modal">
          <iframe
            src={`https://view.officeapps.live.com/op/embed.aspx?src=${linkToPPTFile}`}
            width="100%"
            height="600px"
            frameBorder="0"
            title="slides"
          ></iframe>
          <h3 className="courseIframeTitle"><strong> Lesson_1.pdf </strong> — Updated on June 1, 2022 @ 11:25:34 AM </h3>
          <a onClick={toggleModalThree} className="closeBtnModal">
          {' '}
          <svg xmlns="http://www.w3.org/2000/svg" width="17.688" height="17.688" viewBox="0 0 17.688 17.688">
            <path
              id="close-icon"
              d="M25.188,9.281,23.407,7.5l-7.063,7.063L9.281,7.5,7.5,9.281l7.063,7.063L7.5,23.407l1.781,1.781,7.063-7.063,7.063,7.063,1.781-1.781-7.063-7.063Z"
              transform="translate(-7.5 -7.5)"
              fill="#000"
            ></path>
          </svg>
        </a>
        </Modal>
      </Row>
    </>
  );
};

export default Accordions;
